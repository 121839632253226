import { TextField } from '@mui/material';
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker';
import CustomActionBar from './CustomActionBar';
import { getInputProp } from 'libs/input';

// /**
//  * this component is supported by LocalizationProvider
//  *
//  * @param {MobileDatePickerProps<Date, Date>} props
//  */
export default function RegularDatePicker(props) {
  const { size, fullWidth, inputPropName, inputProps, name, required, label = '日期', ...restProps } = props;
  return (
    <MobileDatePicker
      label={label}
      inputFormat="yyyy-MM-dd"
      openTo="year"
      views={['year', 'month', 'day']}
      toolbarFormat="yyyy-MM-dd"
      renderInput={p => (
        <TextField
          {...p}
          {...getInputProp(inputPropName)}
          fullWidth={fullWidth}
          size={size}
          name={name}
          required={required}
          {...inputProps}
        />
      )}
      components={{
        ActionBar: CustomActionBar,
      }}
      {...restProps}
    />
  );
}
