import { useRef, useState } from "react";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { Stack, Divider, Avatar, List, Button, Typography, Tooltip } from "@mui/material";

import LINKS from "data/links.json";
import { useMe } from "components/Provider/AuthProvider";
import { useConfigService } from "service/config/index";
import useToggle from "libs/hooks/useToggle";
import Cursor from "components/Cursor";
import MenuLink from "./MenuLink";
import MenuButton from "./MenuButton";
import DropdownMenu from "./DropdownMenu";

import { PreSpace, Container, SubContainer, Footer } from "./style";

const MotionList = motion(List);

/**
 * @component
 * @param {Object} props
 * @param {?string} props.title
 */

export default function Menu({ title }) {
  const hoverTimeoutRef = useRef();
  const { useAllConfig } = useConfigService();

  const router = useRouter();
  const me = useMe();
  const { data: config } = useAllConfig();
  const [isOpen, toggle] = useToggle();

  const department = me.departmentId ? me.department.name : null;
  const salePoint = me.salePointId ? me.salePoint.salePointName : null;

  const handleLogout = async () => {
    try {
      await fetch("/api/logout", { method: "POST" });
      me.mutate();
    } catch (err) {
      console.log(err);
    }
  };

  ///
  const [showDot, setShowDot] = useState(false);

  const handleHoverStart = () => {
    clearTimeout(hoverTimeoutRef.current);
    if (!isOpen) {
      setShowDot(true);
    }
  };

  const handleHoverEnd = () => {
    setShowDot(false);
    if (isOpen) {
      const timeout = setTimeout(() => {
        toggle.toFalse();
      }, 250);

      hoverTimeoutRef.current = timeout;
    }
  };

  const handleTransitionEnd = event => {
    setShowDot(false);
    toggle.toTrue();
  };

  return (
    <>
      <PreSpace layout isOpen={isOpen} />
      <Container layout isOpen={isOpen} onHoverStart={handleHoverStart} onHoverEnd={handleHoverEnd}>
        <Cursor show={showDot} onTransitionEnd={handleTransitionEnd} />
        <SubContainer layout>
          <MotionList layout sx={{ padding: "0 0" }}>
            <Stack>
              <MenuButton iconName="menu" onClick={toggle} displayName="選單" />
              <Typography sx={{ display: "none" }}>{`${department || salePoint || "未設定部門/銷售點資訊"}${
                title ? " | " + title : ""
              }`}</Typography>
            </Stack>
            <MenuButton iconName="refresh" displayName="重新整理" onClick={() => router.reload()} />
            <MenuButton iconName="arrowLeft" displayName="往上一頁" onClick={() => router.back()} />
          </MotionList>
          <Divider flexItem />
          <MotionList layout sx={{ overflowY: "auto", padding: "0 0" }}>
            <Stack sx={{ maxWidth: isOpen ? "240px" : "56px", overflowX: "hidden", whiteSpace: "nowrap" }}>
              {LINKS.map(link => {
                if (link.access) {
                  if (link.access !== me.fullAccess) return null;
                }

                if (link.moduleName) {
                  if (!(link.moduleName in (config?.module || {}))) return null;
                }
                return (
                  <Tooltip key={link.name} title={isOpen ? "" : link.displayName} placement="right" followCursor>
                    <span>{link.dropdown ? <DropdownMenu link={link} userAccess={me.fullAccess} /> : <MenuLink link={link} />}</span>
                  </Tooltip>
                );
              })}
              <Tooltip title="POS 系統" placement="right" followCursor>
                <a
                  href={`https://${config?.client_name}.pos.bonsale.info`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <MenuButton iconName="openInNew" displayName="POS 系統" />
                </a>
              </Tooltip>
            </Stack>
          </MotionList>
          <Divider flexItem sx={{ mt: "auto" }} />
          <Footer layout>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar sx={{ width: 36, height: 36 }} />
              <Typography className="footer__doctorName" variant="body2">
                {me.realName}
              </Typography>
            </Stack>
            <Button variant="outlined" color="error" size="small" onClick={handleLogout}>
              登出
            </Button>
          </Footer>
        </SubContainer>
      </Container>
    </>
  );
}
