import { useCallback } from 'react';
import { useRouter } from 'next/router';
import usePacsURL from 'libs/hooks/usePacsURL';
import PATH from 'libs/path';
import Pet from 'libs/models/Pet';

import { TableRow, TableCell, Typography, Divider, Stack } from '@mui/material';
import TooltipIconButton from 'components/UI/Button/TooltipIconButton';
import DeathBrand from 'components/Brand/DeathBrand';
import NewTag from 'components/UI/NewTag';
import {
  EditActionButton,
  InfoActionButton,
  MedicalRecordActionButton,
  PacsActionButton,
  ReservationActionButton,
} from 'features/ActionButton';
import { toOneMember } from 'libs/navPath';

const PetListItem = ({ pet: petData }) => {
  const pet = new Pet(petData);
  const router = useRouter();
  const pacsUrl = usePacsURL(null, pet.petCode.default, pet.petCode.custom);

  const handleClickToMemberInfo = useCallback(() => {
    router.push(toOneMember(pet.member.id));
    // eslint-disable-next-line
  }, []);

  const handleClickToDocument = useCallback(() => {
    router.push(PATH.toDocument(pet.member.id, pet.petId));
    // eslint-disable-next-line
  }, []);

  return (
    <TableRow key={pet.petCode.default} hover>
      <TableCell>
        <Typography variant="body2">{pet.petCode.default}</Typography>
        {pet.petCode.custom && <Typography variant="body2">{pet.petCode.custom}</Typography>}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={0.5}>
          <Typography>{pet.petName}</Typography>
          <DeathBrand data={pet.isKilled} />
          <NewTag time={pet.information.createTime} />
        </Stack>
      </TableCell>
      <TableCell>
        {pet.petType.name} / {pet.petVariety.name}
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          onClick={handleClickToMemberInfo}
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': { color: 'gray' },
          }}
        >
          {pet.member.memberName}
        </Typography>
      </TableCell>
      <TableCell>{pet.member.phone}</TableCell>
      <TableCell>
        <Stack direction="row">
          <MedicalRecordActionButton petId={pet.petId} />
          {pacsUrl && <PacsActionButton anchor={pacsUrl} />}

          <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
          <TooltipIconButton title="電子文件" size="small" type="document" onClick={handleClickToDocument} />
          <ReservationActionButton prePetId={pet.petId} />
          <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
          <InfoActionButton petId={pet.petId} />
          <EditActionButton petId={pet.petId} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PetListItem;
